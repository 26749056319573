<template>
  <div class="page page__content-wrapper smm">
    <div class="page__full-width clothes-block">
      <div class="page__min-container">
        <div class="text__head">
          <div class="top-border"></div>
          Рабочая одежда
        </div>
      </div>
      <div class="page__link-block">
        <a :href="item.url"
           @click.prevent="downloadItem(item)"
           class="btn__circle btn__nav-slider  btn-download">
          <img src="~assets/img/icons/icon-ai.svg" alt="AI">
        </a>
      </div>
    </div>


    <div class="page__full-width smm__block-info-row m-zero">
      <div class="page__min-container page__img-wrapper m-zero">
        <img src="~assets/img/clothes-page/clothes-1.png" alt="Рабочая одежда">
      </div>

    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/brand/letters-and-mailings">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Письма и рассылки
      </router-link>
      <router-link class="next-page__link" to="/brand/souvenir-products">
        Сувенирная продукция
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/brand/souvenir-products">
        Сувенирная продукция
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
// import StylePageSlider from "@/components/Sliders/StylePageSlider";

import Axios from "axios";

export default {
  components: {
    // StylePageSlider
  },
  data() {
    return {
      item: {
        url: '../dowload-file/Brand--Office/clothes/GDP-Clothes.zip',
        label: "GDP-Clothes"
      }
    }
  },
  methods: {
    downloadItem({url, label}) {
      Axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/style/page-block";
@import "../../../assets/style/text-style";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;

  .page__full-width {
    .page__min-container {
      margin-bottom: 0;

      .text__head {
        margin-bottom: 0;
        letter-spacing: 0.15px;
        font-weight: 500;

        .top-border {

        }
      }
    }

    .page__link-block {
      margin-left: 20px;
    }
  }
}

.smm {
  &__block-info {
    &-row {
      display: flex;
      align-items: flex-start;
      margin-bottom: 128px;
    }
  }

  &__sub-title {
    min-width: 112px;
  }

  &__avatar {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    background: linear-gradient(264.59deg, #FF3D3D 0%, #F96400 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn-gradient {
    border: 0.5px solid #F96400;
    border-radius: 24px;
    padding: 7px 16px;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #F96400;
    margin-left: 84px;
    cursor: pointer;
  }
}

.m-zero {
  margin-bottom: 0;
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 208px;
  }
}

.clothes-block {
  .page__min-container {
    width: 100%;
  }

  display: flex;
}

@media screen and (max-width: 900px) {
  .slider-smm-mobile {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
  }
  .text__bold.smm__sub-title {
    margin-bottom: 32px;
  }
  .smm__slider-wrap {
    width: 100%;
  }
  .smm__slider-wrap .style-page__nav-block {
    flex-wrap: wrap;

    .btn__nav-slider {
      min-width: 56px;
      min-height: 56px;
    }
  }
  .style-page__nav-block {
    flex-wrap: wrap;
  }
  .smm__block-info-row {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .smm__btn-gradient {
    position: absolute;
    margin: 0;
    top: 125px;
    left: 175px;
  }
  .smm__avatar {
    position: relative;
    z-index: 1;
  }
  .smm__btn-gradient:before {
    content: '';
    display: flex;
    width: 100%;
    height: 1px;
    background: #F96400;
    position: absolute;
    top: 16px;
    left: -100%;
    z-index: 0;
  }
  .smm__block-info-row {
    margin-bottom: 64px;
    margin-right: 0;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .page__full-width {
    width: 100%;
  }
}
</style>
